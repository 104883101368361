@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap'); */


body {
  margin: 0;
  font-family: "Inter",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: "Inter";
  border-radius: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#crypto-widget {
  position: relative;
}

.plastik-widget {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 96;
  /* height: 100%; */
}

.plastik-widget-displayed {
  height: 100%;
  background-color: rgba(0,0,0,0.6);
}

.plastik-widget-bg {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 90;
  height: 100%;
}

.plastik-wrapper-displayed {
  height: 100%;
}

.plastik-bell {
  background-image: url('assets/bell.svg');
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  margin-top: 4px;
}

.plastik-arrow-down {
  background-image: url('assets/arrow-down.svg');
  width: 28px;
  height: 14px;
  background-repeat: no-repeat;
}

.plastik-logout {
  background-image: url('assets/logout.svg');
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  padding-left: 10px;
  margin-top: 18px;
}

.plastik-wallet {
  background-image: url('assets/wallet.svg');
  width: 18px;
  height: 16px;
  background-repeat: no-repeat;
}

.web3modal-modal-lightbox {
  z-index: 98!important;
}

.loader {
  /* background-image: url('assets/loader.svg'); */
  width: 100px;
  height: 100px;
  margin: 10px auto 10px auto;
}

.tick {
  background-image: url('assets/tick.png');
  width: 100px;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}

.token_address {
  padding: 10px;
  font-size: 12px;
  border: 1px solid #4AC8B1;
  border-radius: 8px;
}

.copy_container {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.copy_btn_img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}